export default [
  {
    name: '文本采集',
    sopType: 5005
  },
  {
    name: '图片采集',
    sopType: 5006
  },
  {
    name: '音频采集',
    sopType: 5007
  },
  {
    name: '视频采集',
    sopType: 5008
  },
  {
    name: '混合采集',
    sopType: 5004
  },
  {
    name: '语音切割',
    sopType: 1
  },
  {
    name: '语音分类',
    sopType: 2
  },
  {
    name: '语音转写',
    sopType: 3
  },
  {
    name: '图片分类',
    sopType: 1001
  },
  {
    name: '简单图片标注',
    sopType: 1002
  },
  {
    name: '复杂图片标注',
    sopType: 1003
  },
  {
    name: '语义分割',
    sopType: 1004
  },
  {
    name: '实例分割',
    sopType: 1005
  },
  {
    name: '全图分割',
    sopType: 1006
  },
  {
    name: '图片追踪',
    sopType: 1007
  },
  {
    name: '超大图标注',
    sopType: 1008
  },
  {
    name: '医疗DICOM',
    sopType: 1009
  },
  {
    name: '文本分类',
    sopType: 2001
  },
  {
    name: '文本翻译',
    sopType: 2002
  },
  {
    name: '命名实体识别',
    sopType: 2003
  },
  {
    name: '视频分类',
    sopType: 3001
  },
  {
    name: '视频目标跟踪',
    sopType: 3002
  }
]
