export const formatTime = (timestamp) => {
  var d = new Date(timestamp * 1000)
  var YYYY = d.getFullYear()
  var MM = d.getMonth() + 1
  var DD = d.getDate()
  var hh = d.getHours()
  var mm = d.getMinutes()
  var ss = d.getSeconds()
  return YYYY + '-' + MM + '-' + DD + ' ' + hh + ':' + mm + ':' + ss
}
