<template>
  <div>
    <p class="h4 pt-4 pb-4">招募公告 > 历史公告</p>
    <el-table
      :data="lists"
      border
      style="width: 100%"
      @expand-change="handleExpandChange">
      <el-table-column label="序号" prop="id"></el-table-column>
      <el-table-column label="项目名称" prop="name"></el-table-column>
      <el-table-column label="发布人" prop="contact"></el-table-column>
      <el-table-column label="发布时间" width="200">
        <template slot-scope="scope">
          <span>{{ formatTime(scope.row.created) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="open(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="历史公告详情"
      :visible.sync="dialogVisible"
      :fullscreen="true"
      width="30%">
      <div class="container">
        <p>序号: {{curRecruit.id}}</p>
        <div class="dialog-item">
          <p>项目名称: {{curRecruit.name}}</p>
          <p>项目要求：{{curRecruit.category === 1 ? '个人项目' : '团队项目'}}</p>
          <p>项目类型：{{thisType}}</p>
          <p>单价：{{curRecruit.price || '--'}}</p>
          <p>截止时间: {{formatTime(curRecruit.deadline)}}</p>
          <p>联系方式：{{curRecruit.contact}}</p>
        </div>
        <p>项目详情：</p>
        <p v-html="curRecruit.detail"></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { recruitsList } from '@/api/tips'
import soptype from '@/lib/types/soptype'
import { formatTime } from '@/lib/format'
export default {
  name: 'history',
  data () {
    return {
      lists: [],
      dialogVisible: false,
      curRecruit: {},
      formatTime: formatTime
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getLists()
    })
  },
  computed: {
    thisType () {
      if (this.curRecruit.type) {
        return soptype.find(v => v.sopType === this.curRecruit.type).name
      } else {
        return ''
      }
    }
  },
  methods: {
    open (e) {
      this.dialogVisible = true
      this.curRecruit = e
    },
    getLists () {
      recruitsList({ status: 9 }).then(
        ({ data = {} }) => {
          this.lists = data.items.filter(v => v.status === 9)
        }
      )
    }
  }
}
</script>
<style lang="less">
.dialog-item {
  display: flex;
  flex-wrap: wrap;
  p {
    width: 50%;
  }
}
</style>
